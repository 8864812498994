<template>
  <div>
    <page-tabs
      :filter-tabs="filterTabsList"
      class="mt-1"
    />
    <router-view />
  </div>
</template>
<script>
import PageTabs from '@/views/main/warehouse/main-order/components/view-top/PageTabs.vue'
import config from '../../config'

export default {
  name: 'List',
  components: { PageTabs },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_WAREHOUSE_DATE_PICKER`, { value: new Date(), params: { sorting: 'list' } })
  },
  setup() {
    const { filterTabsList, MODULE_NAME } = config()
    return {
      filterTabsList,
      MODULE_NAME,
    }
  },
}

</script>
