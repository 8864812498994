<template>
  <div
    class="d-flex"
    style="gap: 12px"
  >
    <b-button
      v-for="(filterTab, idx) in filterTabs"
      :key="idx"
      :variant="currentActiveQuery.includes(filterTab.query) ? 'secondary' : 'btn bg-D9D9D9'"
      @click="onTabChange(filterTab)"
    >
      {{ filterTab.title }}
    </b-button>
  </div>
</template>

<script>
// Components
import { BButton } from 'bootstrap-vue'

// Configs
import config from '../../config'

export default {
  name: 'PageTabs',
  components: {
    BButton,
  },
  props: {
    filterTabs: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      currentActiveQuery: this.$route.path.split('/'),
    }
  },
  watch: {
    $route(newRoute) {
      if (!newRoute) return

      this.currentActiveQuery = newRoute.path
    },
  },
  methods: {
    onTabChange({ query, pageTitle }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_WAREHOUSE_PAGE_TITLE`, pageTitle)
      this.$router.push({ name: `home-warehouse-${query}` })
    },
  },
  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME,
    }
  },
}
</script>
<style lang="scss">
.bg-D9D9D9 {
  background-color: #d9d9d9 !important;
}
</style>
